<script>
/* eslint-disable global-require */
import numberFormatMixin from '@/mixins/numberFormatMixin'
import { TRANSACTION_STATUS, TRANSACTION_TYPE, DATE_RANGE } from '@/constants'

// import { createNamespacedHelpers } from 'vuex'

// const { mapActions } = createNamespacedHelpers('payment')
export default {
  mixins: [numberFormatMixin],

  data() {
    return {
      currentPage: 1,
      searchTerm: '',

      // sort
      sortTerm: {
        field: '',
        type: '',
      },

      // per page
      pageLength: 10,
      pages: ['5', '10', '20'],

      // date picker
      rangeDate: null,
      startDate: null,
      endDate: null,
      configDate: {
        mode: 'range',
      },

      // status filter
      statusSelected: {
        name: this.$t('payment.balanceTransactionsTable.textAllStatus'),
        value: '',
      },
      statusOptions: [
        {
          name: this.$t('payment.balanceTransactionsTable.textAllStatus'),
          value: '',
        },
        {
          name: this.$t('guidePopUp.guideTopUpWise.textDone'),
          value: TRANSACTION_STATUS.DONE,
        },
        {
          name: this.$t('payment.textPending'),
          value: TRANSACTION_STATUS.PENDING,
        },
        {
          name: this.$t('billingPage.statusRejected'),
          value: TRANSACTION_STATUS.REJECTED,
        },
      ],

      // week, month, year filter
      dateSelected: null,
      dateOptions: DATE_RANGE,
    }
  },

  computed: {

    createdDate() {
      return value => (value ? new Date(value).toLocaleString('en-GB') : '')
    },

    statusVariant() {
      const statusColor = {
        [TRANSACTION_STATUS.DONE]: 'light-success',
        [TRANSACTION_STATUS.REJECTED]: 'light-danger',
        [TRANSACTION_STATUS.PENDING]: 'light-warning',
      }

      return status => statusColor[status]
    },

    statusTxt() {
      const statusTxt = {
        [TRANSACTION_STATUS.DONE]: this.$t('guidePopUp.guideTopUpWise.textDone'),
        [TRANSACTION_STATUS.REJECTED]: this.$t('billingPage.statusRejected'),
        [TRANSACTION_STATUS.PENDING]: this.$t('billingPage.statusPending'),
      }

      return status => statusTxt[status]
    },

    transactionTxt() {
      const transactionTxt = {
        [TRANSACTION_TYPE.RECHARGE_USER_BALANCE]: this.$t('payment.balanceTransactionsTable.textAddFunds'),
        [TRANSACTION_TYPE.RECHARGE_ADS_ACCOUNT_BALANCE]: this.$t('payment.balanceTransactionsTable.textAddFunds'),
        [TRANSACTION_TYPE.REFUND_ADS_ACCOUNT_BALANCE]: this.$t('balance.textWithdraw'),
        [TRANSACTION_TYPE.ADMIN_FIX_RECHARGE_MONEY]: this.$t('payment.balanceTransactionsTable.textAddFundsByAdmin'),
        [TRANSACTION_TYPE.ADMIN_FIX_WITHDRAW_MONEY]: this.$t('payment.balanceTransactionsTable.textWithdrawByAdmin'),
        [TRANSACTION_TYPE.REFERRAL_PAYOUT]: this.$t('payment.balanceTransactionsTable.textReferralPayout'),
        [TRANSACTION_TYPE.SYSTEM_WITHDRAW_DUE_TO_INACTIVE]: this.$t('payment.balanceTransactionsTable.textSystemWithdrawal'),
        [TRANSACTION_TYPE.CASHBACK]: this.$t('payment.balanceTransactionsTable.textCashback'),
        [TRANSACTION_TYPE.SETUP_COST]: this.$t('payment.balanceTransactionsTable.textSetupCost'),
        [TRANSACTION_TYPE.LARK_APPROVAL_ADD_FUND_USER_BALANCE]: this.$t('payment.balanceTransactionsTable.textLarkApprovalAddFund'),
      }

      return status => transactionTxt[status]
    },

    amountColor() {
      const txtColor = {
        '-': 'text-danger',
        '+': 'text-success',
      }

      return txt => txtColor[txt]
    },

    adsAccountImg() {
      if (this.isEmediaPlatform) {
        return require('@/assets/images/common/ic-ads-acc-emedia.svg')
      }
      return require('@/assets/images/common/ic-ads-acc.svg')
    },

    baAdsAccountImg() {
      if (this.isEmediaPlatform) {
        return require('@/assets/images/common/ic-ba-acc-emedia.svg')
      }
      return require('@/assets/images/common/ic-ba-acc.svg')
    },

    rangeDates() {
      return {
        from: this.rangeDate.startDate,
        to: this.rangeDate.endDate
          ? this.rangeDate.endDate
          : this.rangeDate.startDate,
      }
    },
  },

  methods: {

    async handleSearch(searching) {
      if (this.debounce !== null) {
        await clearTimeout(this.debounce)
      }

      if (searching === '') {
        this.clearSearch()
      } else if (searching.length > 2) {
        this.currentPage = 1
        this.debounce = setTimeout(() => {
          this.fetchHistory()
        }, 600)
      }
    },

    async handleChangePage(page) {
      this.currentPage = page
      await this.fetchHistory()
    },

    async handlePageChange(active) {
      this.currentPage = 1
      this.pageLength = active
      await this.fetchHistory()
    },

    async onSortChange(params) {
      this.sortTerm.field = params[0].field
      this.sortTerm.type = params[0].type
      await this.fetchHistory()
    },

    async handleDateChange(selectedDates) {
      this.rangeDate = selectedDates
      await this.fetchHistory()
    },

    // async clearDate() {
    //   this.rangeDate = null
    //   this.startDate = null
    //   this.endDate = null
    //   this.dateSelected = null
    //   await this.fetchHistory()
    // },

    clearSearch() {
      this.searchTerm = ''
      this.currentPage = 1
      this.fetchHistory()
    },

    async handleStatusChange() {
      this.currentPage = 1
      await this.fetchHistory()
    },

    // async handlePickDateChange(date) {
    //   const { value } = date
    //   this.currentPage = 1
    //   if (value !== 'ALL') {
    //     const start = value.start.format('YYYY-MM-DD')
    //     const end = value.end.format('YYYY-MM-DD')
    //     this.startDate = start
    //     this.endDate = end
    //     this.rangeDate = `${start} to ${end}`
    //     this.fetchHistory()
    //   } else {
    //     this.clearDate()
    //   }
    // },
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-good-table.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  .ads-avt-img {
    width: 44px;
    height: 44px;
    margin-right: 10px;
  }
  .close-icon {
    .input-group-text {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .badge{
    border-radius: 100px;
    padding: 6px 12px;
    font-weight: 600;
  }

  .input-height-48 {
    .vs__dropdown-toggle {
      border: 1px solid #e0e0e5;
      height: 48px;
    }
  }
</style>
<style lang="scss" scoped>
  .select-input{
    width: 100%;
  }

  .add-fund-btn {
    margin-right: 5px;
    @media (max-width: 767px) {
      margin-bottom: 5px;
    }
  }
</style>
