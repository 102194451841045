<template>
  <div>
    <div class="form-action">

      <!-- filter desktop -->
      <div class="filter-desktop balance-allocation px-1">
        <!-- filter 1 -->
        <div class="d-flex justify-content-end my-2 input-control pl-md-0 mt-1 mt-md-0">
          <btn-loading
            v-if="isPancakeUser"
            class="btn-submit-outline mr-2 pancake-btn"
            variant-convert="btn-submit-outline"
            pill
            @click="navigateToPancake"
          >
            {{ $t('payment.pancake.backToPancake') }}
          </btn-loading>
          <div
            class="status-input input-height select-input"
          >
            <v-select
              v-model="statusSelected"
              class="input-height custom-select-input"
              label="name"
              :options="statusOptions"
              :clearable="false"
              @input="handleStatusChange"
            >
              <template #open-indicator="{ attributes }">
                <span v-bind="attributes">
                  <feather-icon
                    icon="ChevronDownIcon"
                    size="20"
                  />
                </span>
              </template>
            </v-select>
          </div>
        </div>
      </div>
      <!-- /filter desktop -->
    </div>

    <!-- table -->
    <vue-good-table
      id="list-balance-allocation"
      class="vgt-no-border table-balance-allocation"
      mode="remote"
      :columns="columns"
      :rows="accounts"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :pagination-options="{
        enabled: totalAccount > 0,
      }"
      :sort-options="{
        enabled: totalAccount > 0,
      }"
      :is-loading="loading"
      @on-sort-change="onSortChange"
    >

      <template
        slot-scope="props"
        slot="table-column"
      >
        <span
          v-if="props.column.field === 'autoTopup'"
          class="d-flex justify-content-between align-items-center"
        >
          <div class="d-flex align-items-center">
            <p class="mb-0">{{ props.column.label }}</p>
            <feather-icon
              id="tooltip-auto-top-up"
              icon="FileTextIcon"
              size="16"
              class="ml-25 text-success"
              style="min-width: 16px"
            />
            <b-tooltip
              target="tooltip-auto-top-up"
              triggers="hover"
              variant="light"
              data-bs-placement="top"
            >
              {{ $t('payment.balanceAllocationTable.txtTooltipAutoTopUp') }} <a
                :href="guideToAutoTopUpAdAccount"
                target="_blank"
                rel="noopener noreferrer"
                class="text-blue text-underline"
              >{{ $t('payment.balanceAllocationTable.textViewDetail') }}</a>
            </b-tooltip>
          </div>
        </span>

        <span
          v-else-if="props.column.field === 'ThresholdInformation'"
          class="d-flex justify-content-between align-items-center"
        >
          <div class="d-flex align-items-center">
            <p class="mb-0">{{ props.column.label }}</p>
            <feather-icon
              id="tooltip-threshold-information"
              icon="InfoIcon"
              size="16"
              class="ml-25 text-success"
              style="min-width: 16px"
            />
            <b-tooltip
              target="tooltip-threshold-information"
              triggers="hover"
              variant="light"
              data-bs-placement="top"
            >
              {{ $t('payment.balanceAllocationTable.txtTooltipThresholdInfo') }}
            </b-tooltip>
          </div>
        </span>
      </template>

      <template
        slot-scope="props"
        slot="table-row"
      >
        <!-- Column: Ad Account -->
        <div
          v-if="props.column.field === '_id'"
          class="d-flex flex-column justify-content-end"
        >
          <div class="d-flex align-items-center">
            <b-img
              class="ads-avt-img"
              :src="adsAccountImg"
            />
            <div
              v-b-tooltip.hover.top.v-light.html
              class="d-flex flex-column content-ad-account"
              :title="`<span class='underline'>${props.row.name}</span></br>${props.row._id}`"
            >
              <b-link
                class="underline text-primary font-medium text-user wrap-content"
                :href="`https://ads.tiktok.com/i18n/dashboard?aadvid=${props.row._id}`"
                target="_blank"
                rel="noreferrer noopener"
              >
                {{ props.row.name }}
              </b-link>
              <span class="font-14 id-content wrap-content">ID: {{ props.row._id }}</span>
            </div>
          </div>

          <!-- <div class="d-flex flex-wrap mt-1">
            <div v-b-tooltip.hover.top.v-light="isNotApprove(props.row.status) ? contentToolTip('disabled add fund') : ''">
              <div
                id="add-fund-at-payment"
                class="mr-50 button-action d-flex align-items-center cursor-pointer"
                :class="isNotApprove(props.row.status) ? 'cursor-not-allowed opacity-50' : 'text-action'"
                @click="() => {
                  if(!isNotApprove(props.row.status)) {
                    onModalClick('RECHARGE', props.row)
                  }
                }"
              >
                <feather-icon
                  id="add-fund-at-payment-child-1"
                  icon="PlusCircleIcon"
                  size="14"
                  style="margin-right:2px;"
                />
                <p class="mb-0">
                  {{ $t('layout.btnAddFund') }}
                </p>
                <feather-icon
                  v-b-tooltip.top.v-light="!isNotApprove(props.row.status) ? contentToolTip('add') : ''"
                  id="add-fund-at-payment-child-2"
                  icon="InfoIcon"
                  size="12"
                  style="margin-top: -5px; margin-left: 2px"
                />
              </div>
            </div>
            <div v-b-tooltip.hover.top.v-light="isNotApprove(props.row.status) ? contentToolTip('disabled withdraw') : ''">
              <div
                id="withdraw-at-payment"
                class=" mr-50 button-action d-flex align-items-center cursor-pointer"
                :class="isNotApprove(props.row.status) || formatAmount(props.row.balance, props.row.currency) <= 0 ? 'cursor-not-allowed opacity-50' : 'text-action'"
                @click="() => {
                  if(!isNotApprove(props.row.status) && formatAmount(props.row.balance, props.row.currency) > 0) {
                    onModalClick('REFUND', props.row)
                  }
                }"
              >
                <feather-icon
                  span-id="withdraw-at-payment-child-1"
                  icon="MinusCircleIcon"
                  size="14"
                  style="margin-right:2px;"
                />
                <p class="mb-0">
                  {{ $t('balance.textWithdraw') }}
                </p>
                <feather-icon
                  v-b-tooltip.top.v-light="!isNotApprove(props.row.status) ? contentToolTip : ''"
                  span-id="withdraw-at-payment-child-2"
                  icon="InfoIcon"
                  size="12"
                  style="margin-top: -5px; margin-left: 2px"
                />
              </div>
            </div>

            <div
              v-if="autoTopUpAdAccountMode && !isExclusivePartner"
              v-b-tooltip.hover.top.v-light="isNotApprove(props.row.status) ? contentToolTip('disabled auto top-up') : ''"
            >
              <div
                id="setup-auto-topup-at-payment"
                class="cursor-pointer button-action d-flex align-items-center cursor-pointer"
                :class="disableToggle(props.row) || isNotApprove(props.row.status) ? 'cursor-not-allowed opacity-50' : 'text-action'"
                @click="() => {
                  if(!disableToggle(props.row) && !isNotApprove(props.row.status)) {
                    editAutoTopUp(`${selectedAutoTopUp(props.row) ? 'edit' : 'add' }`, props.row)
                  }
                }"
              >
                <feather-icon
                  icon="Edit2Icon"
                  size="14"
                  style="margin-right:2px;"
                />
                <p class="mb-0">
                  {{ selectedAutoTopUp(props.row) ? $t('balance.textEditAutoTopUp') : $t('balance.textSetupAutoTopUp') }}
                </p>
              </div>
            </div>
          </div> -->
        </div>

        <!-- Column: Business Account -->
        <div
          v-else-if="props.column.field === 'BCId'"
          class="d-flex align-items-center"
        >
          <b-img
            rounded
            :src="require('@/assets/images/avatars/1.png')"
            class="avt-img"
          />
          <div class="d-flex flex-column">
            <b-link
              class="underline"
              :href="`https://business.tiktok.com/manage/overview?org_id=${props.row.BCId}`"
              target="_blank"
              rel="noreferrer noopener"
            >
              {{ props.row.name }}
            </b-link>
            <span>ID: {{ props.row.BCId }}</span>
          </div>
        </div>

        <!-- Column: status -->
        <span
          v-else-if="props.column.field === 'status'"
          class="text-nowrap text-capitalize"
        >
          <b-badge
            :variant="adsStatusVariant(props.row.status)"
            class="font-14"
          >
            {{ adsStatusTxt(props.row.status) }}
          </b-badge>
        </span>

        <!-- Column: timezone -->
        <!-- <div
          v-else-if="props.column.field === 'timezone'"
          class="d-flex align-items-center"
        >
          <span
            v-if="props.row.displayTimezone"
            class="text-nowrap text-capitalize"
          >
            {{ props.row.displayTimezone }}
          </span>
          <span v-else>
            {{ props.row.timezone }}
          </span>
        </div> -->

        <!-- Column: Balance -->
        <span v-else-if="props.column.field === 'balance'">
          ${{ formatAmount(props.row.balance, props.row.currency) }}
        </span>

        <!-- Column: Alert -->
        <span v-else-if="props.column.field === 'balanceReminder'">
          <span :class="alertTextColor(props.row.balanceReminder)">
            {{ props.row.balanceReminder ? "Yes" : "No" }}
          </span>
        </span>

        <!-- Column: Auto Topup -->
        <div
          v-else-if="props.column.field === 'autoTopup'"
          class="d-flex align-items-center"
        >
          <b-form-checkbox
            :id="props.row._id"
            class="custom-control-success"
            name="check-button"
            switch
            inline
            :checked="selectedAutoTopUp(props.row)"
            :disabled="disableToggle(props.row)"
            :class="disableToggle(props.row) ? 'toggle-disabled' : ''"
            @change="onChangeAutoTopup('add', $event, props.row)"
          />

          <div v-if="selectedAutoTopUp(props.row)">
            <p
              class="btn-edit"
              @click="() => {
                if(!disableToggle(props.row) && !isNotApprove(props.row.status)) {
                  editAutoTopUp(`${selectedAutoTopUp(props.row) ? 'edit' : 'add' }`, props.row)
                }
              }"
            >
              {{ $t('autoTopUp.setupAutomation.edit') }}
            </p>
          </div>
        </div>

        <!-- Column: TopUp Amount -->
        <!-- <span v-else-if="props.column.field === 'topUpAmount'">
          <span v-if="props.row.autoTopUpSetting && props.row.autoTopUpSetting.amount">{{ props.row.autoTopUpSetting.amount }}</span>
          <span v-else>---</span>
        </span> -->

        <!-- Column: TopUp Amount -->
        <!-- <span v-else-if="props.column.field === 'amountThreshold'">
          <span v-if="props.row.autoTopUpSetting && props.row.autoTopUpSetting.threshold">{{ props.row.autoTopUpSetting.threshold }}</span>
          <span v-else>---</span>
        </span> -->

        <div v-else-if="props.column.field === 'ThresholdInformation'">
          <div class="column-threshold-info">
            <div class="amount-top-up-auto">
              <div class="d-flex align-items-center">
                <b-img
                  class="ic-top-up-amount"
                  :src="require('@/assets/images/pages/payment/ic-top-up-amount.svg')"
                  alt="top-up amount"
                />
                <p class="m-0">
                  $ {{ amountTopUpAuto(props.row) }}
                </p>
              </div>

              <div class="icon-container">
                <feather-icon
                  v-b-tooltip.top.v-light="'Top-up amount'"
                  id="add-fund-at-payment-child-2"
                  icon="InfoIcon"
                  size="14"
                  style="margin-top: -5px; margin-left: 2px"
                />
              </div>
            </div>

            <div class="amount-threshold">
              <div class="d-flex align-items-center">
                <b-img
                  class="ic-top-up-amount"
                  :src="require('@/assets/images/pages/payment/ic-amount-threshold.svg')"
                  alt="top-up amount"
                />
                <p class="m-0">
                  $ {{ amountThresholdAuto(props.row) }}
                </p>
              </div>

              <div class="icon-container">
                <feather-icon
                  v-b-tooltip.top.v-light="'Amount threshold'"
                  id="add-fund-at-payment-child-2"
                  icon="InfoIcon"
                  size="14"
                  style="margin-top: -5px; margin-left: 2px"
                />
              </div>
            </div>
          </div>
        </div>

        <div v-else-if="props.column.field === 'action'">
          <div class="d-flex flex-wrap flex-column align-items-start mt-1 width-max">
            <div
              v-b-tooltip.hover.top.v-light="isNotApprove(props.row.status) ? contentToolTip('disabled add fund') : ''"
              class="mb-1 w-100"
            >
              <btn-loading
                id="add-fund-at-my-ads-account"
                class="btn-add-fund w-100"
                variant-convert="btn-submit-outline"
                :class="isNotApprove(props.row.status)? 'disable-btn' : 'text-action'"
                @click="() => {
                  if(!isNotApprove(props.row.status)) {
                    onModalClick('RECHARGE', props.row)
                  }
                }"
              >
                <div class="d-flex align-items-center">
                  <feather-icon
                    class="button__icon"
                    icon="PlusCircleIcon"
                    size="14"
                    style="margin-right:2px;"
                  />
                  <p class="mb-0 button__label">
                    {{ $t('layout.btnAddFund') }}
                  </p>
                </div>
              </btn-loading>
            </div>

            <div
              v-b-tooltip.hover.top.v-light="canNotWithdraw(props.row.status) ? contentToolTip('disabled withdraw') : ''"
              class="w-100"
            >
              <btn-loading
                id="withdraw-at-my-ads-account"
                class="btn-add-fund w-100"
                variant-convert="btn-submit-outline"
                :class="canNotWithdraw(props.row.status) || formatAmount(props.row.balance, props.row.currency) <= 0 ? 'disable-btn' : 'text-action'"
                @click="() => {
                  if(!canNotWithdraw(props.row.status) && formatAmount(props.row.balance, props.row.currency) > 0) {
                    onModalClick('REFUND', props.row)
                  }
                }"
              >
                <div class="d-flex align-items-center">
                  <feather-icon
                    class="button__icon"
                    icon="MinusCircleIcon"
                    size="14"
                    style="margin-right:2px;"
                  />
                  <p class="mb-0 button__label">
                    {{ $t('balance.textWithdraw') }}
                  </p>
                </div>
              </btn-loading>
            </div>
          </div>
        </div>

        <!-- Column: common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        v-if="totalAccount > 0"
        slot="pagination-bottom"
      >
        <div
          class="d-flex flex-wrap mt-1 footer-tab"
          :class="[
            { 'justify-content-center': $device.mobile },
            { 'justify-content-between': !$device.mobile },
          ]"
        >
          <!-- page length -->
          <div class="d-flex align-items-center mb-0">
            <span
              class="font-14 font-medium"
            >{{ totalAccount }} {{ $t('payment.balanceAllocationTable.textResult') }}</span>
          </div>
          <div v-if="totalAccount > pageLength">
            <b-pagination
              :total-rows="totalAccount"
              :value="currentPage"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0 mt-1 mt-md-0 pagination-table"
              @change="handleChangePage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>

      <!-- custom loading  -->
      <template slot="loadingContent">
        <b-spinner
          variant="primary"
          type="grow"
          small
        />
      </template>

      <!-- no account image -->
      <div
        slot="emptystate"
        class="text-center"
      >
        <no-balance-allocation class="my-5" />
      </div>
      <!-- /no account image -->
    </vue-good-table>
    <!-- /table -->

    <add-fund-modal
      ref="open-handle-balance-account-modal"
      :open-add-fund-modal="openAddFundModal"
      :account="accountSelected"
      :user-info="user"
      @fetch-ads-accounts="fetchAdsAccounts"
    />

    <setup-automation-modal
      ref="open-setup-automation-modal"
      :account-selected="accountSelected"
      :action="action"
      @fetchAdsAccounts="fetchAdsAccounts"
      @cancelModal="cancelModal"
    />

    <no-money-balance-modal
      ref="open-no-money-balance-modal"
      @cancelModal="cancelModal"
    />

    <confirm-turn-off-auto-top-up-modal
      ref="open-confirm-turn-off-auto-top-up-modal"
      :account-selected="accountSelected"
      @fetchAdsAccounts="fetchAdsAccounts"
      @cancelModal="cancelModal"
    />
    <!-- /increase payment modal -->
  </div>
</template>
<script>
/* eslint-disable no-underscore-dangle */
import {
  BPagination,
  BBadge,
  BSpinner,
  BImg,
  BLink,
  VBTooltip,
  BFormCheckbox,
  VBModal,
  BTooltip,
} from 'bootstrap-vue'
// libs & cons
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import { createNamespacedHelpers } from 'vuex'
import Ripple from 'vue-ripple-directive'
import { ADS_ACCOUNT_STATUS } from '@/constants'

// mixins
import tableAdsAccountMixin from '@/mixins/tableAdsAccountMixin.vue'
import numberFormatMixin from '@/mixins/numberFormatMixin'
import generalConfigsMixin from '@/mixins/generalConfigsMixin'
import envMixin from '@/mixins/envMixin'

// component
import { bus } from '@/plugins/bus'
import SetupAutomationModal from '@/views/payment/components/TabsHistory/Modal/SetupAutomationModal.vue'
import NoMoneyBalanceModal from '@/views/payment/components/TabsHistory/Modal/NoMoneyBalanceModal.vue'
import ConfirmTurnOffAutoTopUpModal from '@/views/payment/components/TabsHistory/Modal/ConfirmTurnOffAutoTopUpModal.vue'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import authMixin from '@/mixins/authMixin'
import NoBalanceAllocation from './NoBalanceAllocation.vue'
import AddFundModal from './Modal/AddFundModal.vue'

const { mapGetters } = createNamespacedHelpers('auth')
export default {
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BtnLoading,
    NoBalanceAllocation,
    AddFundModal,
    ConfirmTurnOffAutoTopUpModal,
    NoMoneyBalanceModal,
    SetupAutomationModal,
    BBadge,
    BPagination,
    BSpinner,
    BImg,
    BLink,
    BFormCheckbox,
    // components
    VueGoodTable,
    vSelect,
    BTooltip,
  },
  mixins: [tableAdsAccountMixin, numberFormatMixin, generalConfigsMixin, envMixin, authMixin],
  data() {
    return {
      action: '',
      placeholder: this.$t('payment.AdsAccountHistoryTable.placeholderSelectDate'),
      accountSelected: {},
      rangeDate: {
        startDate: null,
        endDate: null,
      },
      isScrollTable: false,
    }
  },

  computed: {
    ...mapGetters(['user']),

    columns() {
      return [
        {
          label: this.$t('account.textAdsNavTitle'),
          field: '_id',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass: 'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle td-column-id',
        },
        {
          label: this.$t('payment.balanceAllocationTable.adAccountStatusColumn'),
          field: 'status',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass: 'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
        {
          label: this.$t('payment.balanceAllocationTable.adAccountBalanceColumn'),
          field: 'balance',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass: 'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
        {
          label: this.$t('payment.balanceAllocationTable.autoTopUp'),
          field: 'autoTopup',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass: 'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle td-column-auto-top-up',
          hidden: !this.autoTopUpAdAccountMode || this.isExclusivePartner,
          sortable: false,
        },
        {
          label: this.$t('payment.balanceAllocationTable.thresholdInformation'),
          field: 'ThresholdInformation',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass: 'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle td-column-threshold-information',
          hidden: !this.autoTopUpAdAccountMode || this.isExclusivePartner,
          sortable: false,
        },
        {
          label: this.$t('payment.balanceAllocationTable.action'),
          field: 'action',
          thClass: `p-1 border-0 header-table-mf th-column-action ${this.isScrollTable && this.accounts?.length > 0 ? 'scroll-action-column' : ''}`,
          tdClass: `p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle td-column-action ${this.isScrollTable && this.accounts?.length > 0 ? 'scroll-action-column' : ''}`,
          sortable: false,
        },
        // {
        //   label: 'Balance Alert',
        //   field: 'balanceReminder',
        //   thClass: 'p-1 border-0 header-table-mf',
        //   tdClass: 'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        // },
      ]
    },

    isNotApprove() {
      return value => value !== ADS_ACCOUNT_STATUS.SHOW_ACCOUNT_STATUS_APPROVED
    },

    canNotWithdraw() {
      return value => value !== ADS_ACCOUNT_STATUS.SHOW_ACCOUNT_STATUS_APPROVED && value !== ADS_ACCOUNT_STATUS.SHOW_ACCOUNT_STATUS_NOT_APPROVED
    },

    amountBalance() {
      return this.user?.data?.balance
    },

    contentToolTip() {
      return type => {
        if (type === 'add') {
          return `${this.$t('payment.balanceAllocationTable.textTransferMoneysFrom', { platformName: this.platformName })}`
        }
        if (type === 'disabled add fund') {
          return `${this.$t('payment.balanceAllocationTable.textTooltipNotAddFund', { platform: this.platformName })}`
        }
        if (type === 'disabled withdraw') {
          return `${this.$t('payment.balanceAllocationTable.textTooltipNotWithdraw', { platform: this.platformName })}`
        }
        return `${this.$t('payment.balanceAllocationTable.contentTooltipWithdraw', { platformName: this.platformName })}`
      }
    },

    selectedAutoTopUp() {
      return data => data.autoTopUpSetting && data.autoTopUpSetting.status === 'enable'
    },

    disableToggle() {
      return data => data.status !== ADS_ACCOUNT_STATUS.SHOW_ACCOUNT_STATUS_APPROVED
    },

    amountTopUpAuto() {
      return account => account?.autoTopUpSetting?.amount || '---'
    },

    amountThresholdAuto() {
      return account => account?.autoTopUpSetting?.threshold || '---'
    },
  },

  async created() {
    await this.fetchAdsAccounts()
    this.handlePancakeUserAddFundToAdAccount()
    bus.$on('refresh-balance-allocation-table', () => {
      this.resetData()
    })

    this.initScrollVerifyInfoTable()
  },

  mounted() {
    this.$nextTick(() => {
      this.checkHasScrollbar()
    })
  },

  methods: {
    checkHasScrollbar() {
      const content = document.querySelector('#list-balance-allocation .vgt-responsive')
      const widthNavBar = 977
      const hasScrollBar = widthNavBar > content.clientWidth

      if (hasScrollBar) {
        this.isScrollTable = true
      }
    },

    async initScrollVerifyInfoTable() {
      await document.querySelector('#list-balance-allocation')
      document.querySelector('#list-balance-allocation .vgt-responsive').addEventListener('scroll', event => this.handleScrollBar(event))
    },

    handleScrollBar() {
      const content = document.querySelector('.vgt-responsive')
      if (content) {
        const scrollEnd = content.scrollWidth - content.clientWidth
        const scrollLeftRounded = Math.round(content.scrollLeft)

        // Kiểm tra nếu đã cuộn đến cuối
        this.isScrollTable = scrollLeftRounded !== Math.round(scrollEnd - 0.5) && scrollLeftRounded !== scrollEnd
      }
    },

    onModalClick(type, data) {
      this.$refs['open-handle-balance-account-modal'].showModal()
      this.accountSelected = data
      this.openAddFundModal = type === 'RECHARGE' //  [RECHARGE, REFUND]
    },

    resetData() {
      this.currentPage = 1
      this.searchTerm = ''

      this.sortTerm = {
        field: '',
        type: '',
      }

      this.pageLength = 10
      this.statusSelected = {
        name: this.$t('payment.balanceTransactionsTable.textAllStatus'),
        value: '',
      }

      this.rangeDate = {
        startDate: null,
        endDate: null,
      }
      this.dateSelected = null

      this.fetchAdsAccounts()
    },

    formatAmount(balance, currency) {
      const rate = this.USDToVNDRate
      return currency === 'VND' ? this.amountFormat(balance / rate) : balance
    },

    // auto top-up
    cancelModal(checked) {
      if (document.getElementById(this.accountSelected._id)) {
        document.getElementById(this.accountSelected._id).checked = checked
      }
    },

    onChangeAutoTopup(action, event, data) {
      this.action = action
      this.accountSelected = data
      if (event) {
        if (this.user.data.balance === 0) {
          this.$refs['open-no-money-balance-modal'].showModal()
        } else {
          this.$refs['open-setup-automation-modal'].showModal()
        }
      } else {
        this.$refs['open-confirm-turn-off-auto-top-up-modal'].showModal()
      }
    },

    editAutoTopUp(action, data) {
      this.action = action
      this.accountSelected = data
      this.$refs['open-setup-automation-modal'].showModal()
    },

    handlePancakeUserAddFundToAdAccount() {
      const step = localStorage.getItem('pancakeUserStep')
      const { status, ...query } = this.$route.query
      const [adAccount] = this.accounts

      const isAddFundFlow = this.isPancakeUser
        && step === '4'
        && status === 'add-fund-to-ad-account'
        && this.currentPage === 1
        && this.totalAccount === 1
        && adAccount
        && !this.isNotApprove(adAccount.status)

      if (isAddFundFlow) {
        this.onModalClick('RECHARGE', adAccount)
        this.$router.replace({ query })
      }
    },
  },
}
</script>

<style lang="scss">
.table-balance-allocation {

  .td-column-id {
    max-width: 200px;
  }

  .td-column-auto-top-up {
    min-width: 170px;
    width: max-content;
  }

  .td-column-threshold-information {
    min-width: 200px;
    width: max-content;
  }

  .th-column-action {
    position: sticky !important;
    z-index: 1;
    right: 0;
  }

  .td-column-action {
    background-color: #fff;
    max-width: 200px;
    width: max-content;
    position: sticky !important;
    z-index: 1;
    right: 0;
  }

  .scroll-action-column {
    &:after {
      content: "";
      position: absolute;
      height: 101%;
      top: 0;
      left: -13px;
      box-shadow: inset 13px 0px 8px -8px #00000026;
    }
  }

  .vgt-responsive {
    overflow-y: clip;
  }
}

  .input-control{
    .vs__dropdown-toggle{
      height: 40px;
    }
  }

  .tabs-mf .nav-tabs{
    padding: 16px 24px 0;

    .nav-link{
      padding-top: 0;
      padding-bottom: 14px;

      @media(max-width: 767px) {
        padding-bottom: 0px;
        margin-bottom: 14px;
        margin-right: 20px;
      }
    }
  }

  .status-input{
    .vs__selected{
      margin-top: 0 !important;
    }

    .vs__open-indicator{
      margin-top: 0 !important;
    }
  }

  .custom-date-input{
    .vs__dropdown-toggle{
      padding: 0 !important;
    }

    .vs__open-indicator{
      margin: 0 !important;
    }
  }

  .custom-select-input {
    .vs__dropdown-toggle {
      align-items: center;
    }

    @media (max-width: 767px) {
      .vs__selected-options {
        font-size: 12px
      }

      .vs__dropdown-menu {
        font-size: 12px
      }
    }
  }
</style>

<style lang="scss" scoped>
.content-ad-account {
  width: calc(100% - 54px);

  .wrap-content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.select-input {
  min-width: 250px;
  width: 250px;
  @media (max-width: 767px) {
    min-width: 150px;
    width: 150px;
  }
}

.pancake-btn {
  min-width: 170px;
  @media (max-width: 767px) {
    min-width: 140px;
  }
}

.filter-mobile{
  padding: 0 20px;
}

.id-content{
  color:#16213EB2;
}

.btn-disabled{
  display: none;
}

.balance-allocation{
  padding: 0 24px;
}

.input-height{
  height: 40px;
}

.text-user{
  margin-bottom: 5px;
}

.footer-tab {
  padding: 0 1rem 1rem;
}

.btn-add-fund, .btn-withdraw{
  display: flex;
  align-items: center;
  border-radius: 8px !important;
  width: max-content;
}

.btn-withdraw {
  margin-top: 8px;
}

.btn-disabled {
  color: rgba(22, 33, 62, 0.7);
  border: 1px solid rgba(22, 33, 62, 0.7) !important;
}

.column-threshold-info {

.amount-top-up-auto {
  display: flex;
  align-items: center;
  margin-bottom: 4px;

  .ic-top-up-amount {
    margin-right: 6px;
  }

  .icon-container {
    margin-left: 1rem;
  }
}

.amount-threshold {
  display: flex;
  align-items: center;

  .ic-top-up-amount {
    margin-right: 6px;
  }

  .icon-container {
    margin-left: 1rem;
  }
}
}

.btn-add-fund {
  padding: 6px 12px;
  border-radius: 8px;
}

.btn-edit {
  font-weight: 600;
  margin: 0;
  cursor: pointer;
  color: #2667FF;
}

.disable-btn {
  opacity: .5;
  cursor: not-allowed !important;

  &:hover {
    box-shadow: none !important;
  }
}
</style>
