<template>
  <b-modal
    ref="modal-request-refund-success"
    id="modal-confirm-request"
    hide-footer
    hide-header
    centered
  >

    <!-- body content -->
    <div class="body-content-modal">
      <div class="icon-container">
        <b-img
          :width="200"
          :src="bgRequestRefund"
        />
      </div>

      <div class="content-request-success">
        <h4 class="title-content-request">
          {{ $t('payment.autoRefundFeature.titleRequestRefundSuccess') }}
        </h4>
        <p class="content-request">
          {{ $t('payment.autoRefundFeature.txtToEnsureAQuick') }} <span class="text-gradient">{{ $t('payment.autoRefundFeature.txtWithinTheNext') }}</span>
        </p>
      </div>
    </div>

    <!--modal footer-->
    <div class="d-flex justify-content-center">
      <btn-loading
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant-convert="btn-submit"
        pill
        @click="hideModalRequestSuccess"
      >
        {{ $t('common.btnClose') }}
      </btn-loading>
    </div>
    <!--/modal footer-->
  </b-modal>
</template>
<script>
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-underscore-dangle */
/* eslint-disable global-require */

import {
  BModal,
  BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import BtnLoading from '@/layouts/components/BtnLoading.vue'
import envMixin from '@/mixins/envMixin'

export default {
  components: {
    BModal,
    BImg,
    BtnLoading,
  },

  directives: {
    Ripple,
  },

  mixins: [envMixin],

  props: {
    infoRequestRefund: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {

    }
  },

  computed: {
    bgRequestRefund() {
      if (this.isAdSolutions) {
        return require('@/assets/images/pages/payment/bg-request-refund-success-ad-solutions.jpg')
      }
      return require('@/assets/images/pages/payment/bg-request-refund-success.png')
    },

  },

  methods: {
    hideModalRequestSuccess() {
      this.$refs['modal-request-refund-success'].hide()
    },

    showModalRequestSuccess() {
      this.$refs['modal-request-refund-success'].show()
    },
  },
}
</script>

<style lang="scss">
  #modal-confirm-request {

    .modal-body {
      padding: 40px;
    }
  }
</style>

<style lang="scss" scoped>
  .top-modal {
    margin-bottom: 32px;
  }

  .body-content-modal {

    .icon-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 24px;
    }

    .content-request-success {
      text-align: center;
      margin-bottom: 24px;

      .title-content-request {
        font-size: 20px;
        margin-bottom: 4px;
      }

      .content-request {
        font-size: 14px;
        margin: 0;
      }
    }

    .info-request {
      margin-bottom: 12px;

      .title-info {
        font-size: 12px;
        font-weight: 500;
        margin: 0;
      }

      .content-info {
        font-size: 16px;
        font-weight: 600;
        margin: 0;
      }
    }
  }
</style>
